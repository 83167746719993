<template>
  <div class="attendancePerson">
    <AttendanceMenu />
    <!-- 筛选项 -->
    <div class="wrap">
      <el-form :inline="true">
        <el-form-item label="项目名称">
          <el-select
            size="small"
            v-model="currentProject"
            placeholder="请选择项目"
          >
            <el-option
              v-for="item in projectList"
              :key="item.id"
              :label="item.proName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="参建单位">
          <el-select
            size="small"
            v-model="currentUnit"
            placeholder="请选择参建单位"
            clearable
          >
            <el-option
              v-for="item in unitList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="部门班组">
          <el-select
            size="small"
            v-model="currentDepartment"
            placeholder="请选择部门/班组"
            clearable
          >
            <el-option
              v-for="item in departmentList"
              :key="item.id"
              :label="item.labourGroupName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="员工姓名">
          <el-input
            size="small"
            v-model="consumerName"
            placeholder="请输入员工姓名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证号">
          <el-input
            size="small"
            v-model="certificateOn"
            placeholder="请输入身份证号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="员工工号">
          <el-input
            size="small"
            v-model="consumerNo"
            placeholder="请输入员工工号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="员工状态">
          <span
            class="filterType"
            @click="workingState = null"
            :class="[workingState == null ? 'filterTypeActive' : '']"
            >不限</span
          >
          <!-- <span
            class="filterType"
            @click="workingState = 0"
            :class="[workingState == 0 ? 'filterTypeActive' : '']"
            >未进场</span
          > -->
          <span
            class="filterType"
            @click="workingState = 1"
            :class="[workingState == 1 ? 'filterTypeActive' : '']"
            >在场</span
          >
          <span
            class="filterType"
            @click="workingState = 2"
            :class="[workingState == 2 ? 'filterTypeActive' : '']"
            >退场</span
          >
        </el-form-item>
      </el-form>
    </div>

    <!-- 功能按钮 -->
    <div class="wrap-blank">
      <!-- <el-button type="success" size="small" plain icon="el-icon-download"
        >导出当前数据</el-button
      >
      <el-button type="success" size="small" plain icon="el-icon-download"
        >导出全部数据</el-button
      > -->
    </div>

    <!-- 数据列表 -->
    <div class="wrap">
      <el-table :data="consumerList" style="width: 100%" @row-click="setWg">
        <el-table-column prop="readDate" label="打卡时间" width="180">
        </el-table-column>
        <el-table-column prop="consumerName" label="姓名" width="100">
        </el-table-column>
        <el-table-column prop="consumerNo" label="员工工号" width="100">
        </el-table-column>
        <el-table-column
          prop="corporationName"
          label="参建单位"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column prop="groupName" label="班组" width="100">
        </el-table-column>
        <el-table-column
          prop="title"
          label="工种"
          :show-overflow-tooltip="true"
          width="150"
        >
        </el-table-column>
        <el-table-column prop="readerName" label="门名称" width="180">
        </el-table-column>
        <el-table-column prop="inOut" label="今日出入" width="100">
          <template slot-scope="scope">
            <span :class="'inOut' + scope.row.inOut" v-if="scope.row.inOut == 0"
              >出门</span
            >
            <span :class="'inOut' + scope.row.inOut" v-if="scope.row.inOut == 1"
              >进门</span
            >
            <span :class="'inOut' + scope.row.inOut" v-if="scope.row.inOut == 3"
              >未出入</span
            >
          </template>
          <!-- 0出门 1进门 3未出入 -->
        </el-table-column>
      </el-table>

      <el-pagination
        v-if="consumerList.length"
        layout="total, sizes, prev, pager, next"
        :page-size.sync="pageSize"
        :current-page.sync="pageNum"
        :page-sizes="[2, 5, 10, 20, 50]"
        :total="totals"
        @current-change="getWgRecordPage"
        @size-change="getWgRecordPage"
      >
      </el-pagination>

      <el-empty description="暂无数据" v-if="!consumerList.length"></el-empty>
    </div>

    <!-- 人员的考勤记录 -->
    <el-dialog
      :title="currentWgName + ' 的考勤记录'"
      custom-class="wg-record"
      :visible.sync="dialogTableVisible"
      @close="closeWgDialog"
    >

      <div class="dialog-wrap">
        <el-form :inline="true">
          <el-form-item label="考勤日期">
            <el-date-picker
              size="small"
              v-model="WgDateZone"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button size="small" @click="searchWg">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      

      <el-table :data="WgRecord" style="width: 100%">
        <el-table-column prop="readDate" label="打卡时间" width="180">
        </el-table-column>
        <el-table-column prop="consumerName" label="姓名" width="100">
        </el-table-column>
        <el-table-column prop="consumerNo" label="员工工号" width="100">
        </el-table-column>
        <el-table-column
          prop="corporationName"
          label="参建单位"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column prop="groupName" label="班组" width="100">
        </el-table-column>
        <el-table-column
          prop="title"
          label="工种"
          :show-overflow-tooltip="true"
          width="150"
        >
        </el-table-column>
        <el-table-column prop="readerName" label="门名称" width="180">
        </el-table-column>
        <el-table-column prop="inOut" label="进出" width="100">
          <template slot-scope="scope">
            <span :class="'inOut' + scope.row.inOut" v-if="scope.row.inOut == 0"
              >出门</span
            >
            <span :class="'inOut' + scope.row.inOut" v-if="scope.row.inOut == 1"
              >进门</span
            >
            <span :class="'inOut' + scope.row.inOut" v-if="scope.row.inOut == 3"
              >未出入</span
            >
          </template>
          <!-- 0出门 1进门 3未出入 -->
        </el-table-column>
      </el-table>


      <el-pagination
        v-if="WgRecord.length"
        layout="total, sizes, prev, pager, next"
        :page-size.sync="WgPageSize"
        :current-page.sync="WgPageNum"
        :page-sizes="[2, 5, 10, 20, 50]"
        :total="WgTotals"
        @current-change="getByIdWgRecord"
        @size-change="getByIdWgRecord"
      >
      </el-pagination>
    </el-dialog>
  </div>
</template>

<style lang="less" scope>
.attendancePerson {
  .el-form-item {
    margin-bottom: 0;
  }
  .inOut0 {
    font-weight: 700;
    color: #f56c6c;
  }
  .inOut1 {
    font-weight: 700;
    color: #67c23a;
  }
  .inOut3 {
    font-weight: 700;
    color: #e6a23c;
  }
  .wg-record {
    width: 1200px;
    background-color: #f0f0f0;
  }
}
</style>

<script>
import AttendanceMenu from "@/components/AttendanceMenu.vue";

export default {
  name: "AttendancePerson",
  components: {
    AttendanceMenu,
  },
  data() {
    return {
      currentProject: null,
      currentUnit: null,
      currentDepartment: null,

      projectList: [],
      unitList: [],
      departmentList: [],
      consumerList: [],
      workTypeList: [],
      cultureList: [
        {
          value: "0",
          label: "小学",
        },
        {
          value: "1",
          label: "初中",
        },
        {
          value: "2",
          label: "高中",
        },
        {
          value: "3",
          label: "中专",
        },
        {
          value: "4",
          label: "大专",
        },
        {
          value: "5",
          label: "本科",
        },
        {
          value: "6",
          label: "硕士",
        },
        {
          value: "7",
          label: "博士",
        },
      ],
      ctypeList: [
        {
          value: null,
          label: "其他",
        },
        {
          value: "1",
          label: "管理人员",
        },
        {
          value: "2",
          label: "普通人员",
        },
      ],

      consumerName: "",
      certificateOn: "",
      consumerNo: "",
      workingState: null,

      pageNum: 1,
      pageSize: 10,
      totals: 0,

      dialogTableVisible: false,
      currentWgCid: null,
      currentWgName: null,
      WgRecord: [],
      WgDateZone: [],
      WgPageNum: 1,
      WgPageSize: 10,
      WgTotals: 0,
    };
  },
  mounted() {
    this.getWorkTypeList(); // 加载工种列表数据
    this.getProjectList(); // 加载项目列表数据
  },
  watch: {
    currentProject: function (val) {
      this.currentUnit = null;
      this.pageNum = 1;
      this.getUnitList(); // 加载单位列表数据
      this.getWgRecordPage(); // 获取人员考勤列表
    },
    currentUnit: function (val) {
      this.currentDepartment = null;
      this.pageNum = 1;
      this.getDepartmentList(); // 加载部门班组列表数据
      this.getWgRecordPage(); // 获取人员考勤列表
    },
    currentDepartment: function (val) {
      this.pageNum = 1;
      this.getWgRecordPage(); // 获取人员考勤列表
    },
    workingState: function () {
      this.pageNum = 1;
      this.getWgRecordPage(); // 获取人员考勤列表
    },
  },
  methods: {
    // 加载工种列表数据
    getWorkTypeList: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {};
      that
        .$axiosAdmin({
          method: "get",
          url: "api/admin/dict/type/work_type",
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log("getWorkTypeList =>", res);
          that.workTypeList = res.data;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 加载项目列表数据
    getProjectList: function () {
      var that = this;
      that.radio = null;
      that.currentUnit = null;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        proName: "",
        projectSysNo: "",
        proAddress: "",
        pageNum: 1,
        pageSize: 10,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/project/projectSearch",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.projectList = res.data.projectList;
          if (res.data.projectList.length) {
            that.currentProject = res.data.projectList[0].id;
          }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 加载单位列表数据
    getUnitList: function () {
      var that = this;
      that.radio = null;
      var data = {
        companyType: "",
        companyName: "",
        address: "",
        projectId: that.currentProject,
        pageNum: 1,
        pageSize: "",
      };
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/dept/InquireCompany",
          data: data,
        })
        .then((res) => {
          console.log(res);
          that.unitList = res.data.depts;
          // if (res.data.depts.length) {
          //   that.currentUnit = res.data.depts[0].id;
          // }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 获取部门/班组列表数据
    getDepartmentList: function () {
      var that = this;
      that.radio = null;
      var data = {
        labourGroupName: "",
        projectId: that.currentProject,
        deptId: that.currentUnit,
        pageNum: 1,
        pageSize: 100,
      };
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/labourgroup/labourGroupSearch",
          data: data,
        })
        .then((res) => {
          console.log("getDepartmentList =>", res);
          that.totals = res.data.totals;
          that.departmentList = res.data.labourGroupList;
          // if (res.data.labourGroupList.length) {
          //   that.currentDepartment = res.data.labourGroupList[0].id;
          // }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 获取人员考勤列表
    getWgRecordPage: function () {
      var that = this;
      that.checkList = [];
      that.checkAll = false;
      var data = {
        projectId: that.currentProject,
        companyId: that.currentUnit,
        groupId: that.currentDepartment,
        consumerName: that.consumerName,
        certificateOn: that.certificateOn,
        consumerNo: that.consumerNo,
        workingState: that.workingState,
        pageNum: that.pageNum,
        pageSize: that.pageSize,
      };
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/wgrecord/getWgRecordPage",
          data: data,
        })
        .then((res) => {
          console.log("getWgRecordPage =>", res);
          that.totals = res.data.totals;
          that.consumerList = res.data.wgConsumerRecordList;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 搜索按钮事件
    searchList: function () {
      this.pageNum = 1;
      this.getWgRecordPage(); // 获取人员考勤列表
    },
    // 点击人员列表，设置当前要查询的人员
    setWg: function (row, column, event) {
      var that = this
      console.log("setWg =>", row, column, event);
      this.currentWgCid = row.cid;
      this.currentWgName = row.consumerName;
      this.getByIdWgRecord()
    },
    // 获取当前人员的考勤数据
    getByIdWgRecord: function () {
      var that = this
      var data = {
        pageNum: that.WgPageNum,
        pageSize: that.WgPageSize,
        cid: that.currentWgCid,
        startTime: that.WgDateZone[0] || '',
        endTime: that.WgDateZone[1] || '',
      };
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/wgrecord/getByIdWgRecord",
          data: data,
        })
        .then((res) => {
          console.log("getByIdWgRecord =>", res);
          that.WgTotals = res.data.totals;
          that.WgRecord = res.data.wgConsumerRecordList;
          that.dialogTableVisible = true
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 搜索考勤数据
    searchWg: function () {
      this.WgPageNum = 1
      this.getByIdWgRecord()
    },
    // 关闭考勤窗口
    closeWgDialog: function () {
      this.WgPageNum = 1
    }
  },
};
</script>
